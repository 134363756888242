import React from "react"

import Layout from "../../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../components/seo"

import Button from "../../components/button"
import BiContent from "../../components/bi-content"
import MainBanner from "../../components/main-banner"
import ConceptSection from "../../components/concept-section"
import GridLogo from "../../components/grid-logo"
import MeiquerTeam from "../../components/meiquer-team"

import PlaceholderImage from "../../assets/placeholders/placeholder-01@2x.png"

const mainHeaderContent = (
  <div>
    <p
      style={{
        marginBottom: "1rem",
        color: "black",
        fontWeight: 500,
        fontSize: "1.3em",
      }}
    >
      Áreas de Trabajo.
    </p>
    <p>
      La Estación desarrolla proyectos alrededor de cinco Áreas de Trabajo:
      Comunidades de Juego; Agentes de Cambio; Niñas, niños y acompañantes;
      Entorno; y Organización.
    </p>
    <p>Todas interactuán entre sí, complementándose.</p>
  </div>
)

const mainHeaderTitle = (
  <h1 className="w-normal">
    <span className="w-bold">Ecosistema</span> de{" "}
    <span className="c-turquoise w-bold">Juego</span>
  </h1>
)

const communitiesBannerContent = (
  <>
    <h2 className="w-normal">
      <span className="w-bold">Comunidades</span> de{" "}
      <span className="c-turquoise w-bold">Juego</span>
    </h2>
    <p>
      Colaboramos con cinco comunidades de juego, reactivando espacios públicos
      donde nuestros agentes de cambio llevan a cabo talleres de juego creativo.
      Estos espacios son las <span className="w-bold">Estaciones Meiquer</span>.
    </p>
    <p>
      Nuestros programas duran entre 8 y 10 semanas, se realizan 2 veces al año
      y tienen entrada libre en espacios públicos.
    </p>
    <div className="columns">
      <div className="column is-two-thirds pl-0">
        <Button linkTo="/estaciones-juego">Conocer Estaciones</Button>
      </div>
    </div>
  </>
)

const changeMakersBannerContent = (
  <>
    <h2 className="w-normal">
      <span className="w-bold">Agentes</span> de{" "}
      <span className="c-turquoise w-bold">Cambio</span>
    </h2>
    <p>
      Cada semestre seleccionamos y capacitamos alumnos universitarios del
      Tecnológico de Monterrey Campus Guadalajara con la Metodología Meiquer,
      inspirada en el Espiral de Juego Creativo.
    </p>
    <p>
      Los agentes de cambio reciben horas de servicio social a través de una
      experiencia comunitaria. Tres de ellos ya son parte del equipo Meiquer.
    </p>
  </>
)

const kidsBannerContent = (
  <>
    <h2 className="w-normal">
      <span className="w-bold">Niñas, Niños</span> y{" "}
      <span className="c-turquoise w-bold">Acompañantes</span>
    </h2>
    <p>
      Jugamos con niñas y niños a través de programas, talleres, cursos y
      experiencias digitales. Sabemos que el impacto cambia con la experiencia y
      es por eso que buscamos generar momentos especiales y significativos de
      juego.
    </p>
    <p>
      Reconocemos a los acompañantes como las mamás, papás, tutores, abuelas,
      abuelos, hermanos, hermanas y todas y todos aquellos que nos ayudan a
      acercar el juego creativo.
    </p>
  </>
)

const alliesBannerContent = (
  <>
    <h2 className="w-normal">
      <span className="c-turquoise w-bold">Entorno</span> de{" "}
      <span className="w-bold">Aliados</span>
    </h2>
    <p>
      Nuestro entorno de aliados, clientes y asesores nos ayuda a jugar más y
      llegar a más lugares.
    </p>
    <p>
      Con nuestros aliados colaboramos, co-diseñamos actividades, co-creamos
      experiencias y crecemos el alcance del juego.
    </p>
    <p>
      Actualmente, contamos 10 aliados en todos los sectores: empresas,
      organizaciones y gobierno. Juntos, transformamos comunidades.
    </p>
  </>
)

const teamBannerContent = (
  <>
    <h2 className="w-normal">
      <span className="w-bold">Organización</span> y{" "}
      <span className="c-turquoise w-bold">equipo</span>
    </h2>
    <p>
      Estación Meiquer comenzó con la iniciativa Sergio Hernández, profesor y
      papá, quien vio en el juego una forma de cambiar las cosas.
    </p>
    <p>
      En 2020, somos un equipo de diez agentes de cambio que creen en la
      importancia de jugar, y que, por supuesto, ¡juegan mucho!
    </p>
    <p>
      El equipo Meiquer cuenta con mucho talento para transformar comunidades
      jugando.
    </p>
  </>
)

const communitiesContent = [
  {
    content: (
      <>
        <h4>
          <span className="c-turquoise">194</span> Programas
        </h4>
        <p>
          Han jugado y aprendido en nuestros programas dentro de las comunidades
          de juego.
        </p>
      </>
    ),
  },
  {
    content: (
      <>
        <h4>
          <span className="c-turquoise">14</span> Programas
        </h4>
        <p>
          Implementados desde el 2016, creciendo de una a cinco comunidades en
          menos de 4 años.
        </p>
      </>
    ),
  },
  {
    content: (
      <>
        <h4>
          <span className="c-turquoise">5</span> Comunidades
        </h4>
        <p>
          Con las que colaboramos: Jocotán, Laureles, Las Águilas, San Juan de
          Ocotán y Santa Ana de Tepetitlán.
        </p>
      </>
    ),
  },
]

const changeMakersContent = [
  {
    content: (
      <>
        <h4>
          <span className="c-turquoise">55</span> Alumnxs
        </h4>
        <p>
          Han realizado colaborado como instructures de juego, contadores de
          historias o diseñadores de servicios.
        </p>
      </>
    ),
  },
  {
    content: (
      <>
        <h4>
          <span className="c-turquoise">27</span> Capacitadxs
        </h4>
        <p>
          Con la Metodología Meiquer para diseñar e implementar actividades de
          juego creativo.
        </p>
      </>
    ),
  },
  {
    content: (
      <>
        <h4>
          <span className="c-turquoise">9</span> Agentes de Cambio
        </h4>
        <p>
          Han realizado actividades más allá de su servicio, ya sea como
          instructores contratados o integrándose al equipo.
        </p>
      </>
    ),
  },
]

const kidsContent = [
  {
    content: (
      <>
        <h4>
          <span className="c-turquoise">1,162</span> Niñas y niños
        </h4>
        <p>
          Han jugado con nosotros en cursos programas, talleres y experiencias
          digitales a lo largo de 4 años.
        </p>
      </>
    ),
  },
  {
    content: (
      <>
        <h4>
          <span className="c-turquoise">182</span> Talleres
        </h4>
        <p>
          Implementados en Zapopan, Autlán de Navarro, Casimiro Castillo y en
          plataformas digitales.
        </p>
      </>
    ),
  },
  {
    content: (
      <>
        <h4>
          <span className="c-turquoise">260</span> Actividades
        </h4>
        <p>
          Diseñadas por el equipo Meiquer y las y los agentes de cambio a través
          de la Metodología Meiquer.
        </p>
      </>
    ),
  },
]

const AreasTrabajoPage = props => {
  const data = useStaticQuery(graphql`
    query AreasTrabajoQuery {
      jocotan2Img: file(relativePath: { eq: "meiquer-jocotan-02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      meiquerOrgImg: file(relativePath: { eq: "meiquer-org-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jocotan3Img: file(relativePath: { eq: "meiquer-jocotan-03.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fonnorImg: file(relativePath: { eq: "meiquer-fonnor.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      meiquerOrg5Img: file(relativePath: { eq: "meiquer-org-05.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={props.location}>
      <SEO title="Áreas de Trabajo" />
      <BiContent
        image={PlaceholderImage}
        content={mainHeaderContent}
        title={mainHeaderTitle}
      />
      <MainBanner
        image={data.jocotan2Img.childImageSharp.fluid}
        content={communitiesBannerContent}
        fromBottom={false}
        anchor="comunidades"
      />
      <ConceptSection concepts={communitiesContent} />
      <MainBanner
        image={data.meiquerOrgImg.childImageSharp.fluid}
        content={changeMakersBannerContent}
        fromBottom={false}
        anchor="agentes-cambio"
      />
      <ConceptSection concepts={changeMakersContent} />
      <MainBanner
        image={data.jocotan3Img.childImageSharp.fluid}
        content={kidsBannerContent}
        fromBottom={false}
        anchor="ninas-ninos"
      />
      <ConceptSection concepts={kidsContent} />
      <MainBanner
        image={data.fonnorImg.childImageSharp.fluid}
        content={alliesBannerContent}
        fromBottom={false}
        anchor="entorno"
      />
      <GridLogo hideTitle />
      <MainBanner
        image={data.meiquerOrg5Img.childImageSharp.fluid}
        content={teamBannerContent}
        fromBottom={false}
        anchor="organizacion"
      />
      <MeiquerTeam />
    </Layout>
  )
}

export default AreasTrabajoPage
